import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import PostCard from '../components/postCard'

const Category = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const catHeader = `「${category}」カテゴリの記事（${totalCount}件）`
  const tags = data.tags.group
  const recommends = data.recommends

  return (
    <Layout>
      <div className="">
        <div className="w-full">
          <h1 className="pl-3 md:pl-0 text-lg mb-6">{catHeader}</h1>
          <div className="">
            {edges.map(({ node }) => {
              return <PostCard node={node} />
            })}
          </div>
        </div>
        <Sidebar recommends={recommends} tags={tags} />
      </div>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            category
            img {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    recommends: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { recommend: { eq: true } } }
      limit: 5
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            description
            img {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
